import {
	useState, useEffect, useCallback, ChangeEvent, useRef
} from 'react';
import styles from '../FilterOptions.module.scss';
import { Spacer, TypeAhead } from '@components/index';
import { SPACER_DIRECTION, TypeAheadOption } from '@types';
import axios from 'axios';
import { debounce } from 'lodash';
import SearchIcon from '@mui/icons-material/Search';

type FilterProps = {
    meta: any;
    header: any;
    filterData: any;
    onChange: (val:any) => void;
	filterType: 'carrierLookup' | 'carrierLookupExternalId';
}

const getLabel = (value: string, options: TypeAheadOption[]) => {
	const option = options?.find((opt) => opt.value === value);

	return (option && typeof option.label === 'string') ? option.label : value;
}

export const FilterCarrierLookup = (props:FilterProps) => {
	const {
		meta,
		filterData,
		onChange,
		filterType,
	} = props;
	const [newFields, setNewFields] = useState(0);
	const [inputVals, setInputVals] = useState<any[]>([]);
	const [selectOpts, setSelectOpts] = useState<any[]>([]);
	const currentSearchField = useRef<number>(0);
	const operator = filterType === 'carrierLookupExternalId' ? 'is' : 'contains';

	const changeFieldCount = (add:boolean, key:number = 0) => {
		const newFilterData = { ...filterData };
		if (add) {
			setNewFields(newFields + 1);
			newFilterData.repeats = newFields + 1;
			newFilterData.value = inputVals;
		} else {
			if (key > 0) {
				const cloned = [...inputVals].filter((_, index) => {
					return index !== key;
				});
				setInputVals(cloned);
				newFilterData.value = cloned;
			}
			setNewFields(newFields - 1);
			newFilterData.repeats = newFields - 1;
		}
		newFilterData.columnField = meta?.databaseLocation;
		newFilterData.operatorValue = newFields === 0 ? operator : 'In';
		onChange(newFilterData);
	}

	const fetchCarriers = useCallback(debounce((v, i) => {
		// if isOnboard == true get all carriers including staging user else only active carriers
		const searchApi = meta?.filterOptions?.isOnboard ? 'search-include-staging' : 'search';
		axios.get(`${window.location.origin}/admin/carrier/${searchApi}?q=${encodeURIComponent(v)}`).then((response:any) => {
			const carriers = response?.data?.data?.carriers;
			const newOpts = carriers.map((item:any) => {

				return {
					value: filterType === 'carrierLookupExternalId' ? item.customer_external_id : item.trade_name,
					label: item.trade_name,
				}
			});
			const clonedSelectOpts = [...selectOpts];
			clonedSelectOpts[i] = newOpts;
			setSelectOpts(clonedSelectOpts);
		}).catch((e) => console.log(e));
	}, 500), []);

	const updateSelectCarrier = (v: ChangeEvent<HTMLInputElement> | string | TypeAheadOption, i:number, fromSearch:boolean) => {
		const cloned = [...inputVals];
		currentSearchField.current = i;
		if ((v as ChangeEvent<HTMLInputElement>).target &&
				(v as ChangeEvent<HTMLInputElement>).target.value !== '' && !fromSearch) {
			fetchCarriers((v as ChangeEvent<HTMLInputElement>).target.value, i);
			cloned[i] = (v as ChangeEvent<HTMLInputElement>).target.value;
			setInputVals(cloned);
		} else if ((v as ChangeEvent<HTMLInputElement>).target &&
				(v as ChangeEvent<HTMLInputElement>).target.value === '') {
			cloned[i] = '';
			setInputVals(cloned);
		} else if (fromSearch) {
			cloned[i] = (v as TypeAheadOption).value;
			setInputVals(cloned);
			const newFilterData = {
				...filterData,
				value: cloned,
				repeats: newFields,
				columnField: meta?.databaseLocation,
				operatorValue: newFields === 0 ? operator : 'In'
			}
			onChange(newFilterData);
		}
	}

	useEffect(() => {
		if (filterData?.value) {
			setInputVals(filterData.value || []);
			setNewFields(filterData.repeats || 0);
		} else {
			setInputVals([]);
			setNewFields(0);
		}
	}, [filterData]);
	return (
		<>
			{
				<div className={styles.inputContainer}>
					<div style={{ flex: 1 }}>
						<TypeAhead
							name={'Filter-Option-Carrier-Lookup'}
							value={getLabel(inputVals[0], selectOpts[0]) || ''}
							onChange={(v) => updateSelectCarrier(v, 0, false)}
							onSelected={(v) => updateSelectCarrier(v, 0, true)}
							options={selectOpts[0] || []}
							keepErrorSpace={false}
						/>
					</div>
					{meta?.filterOptions?.allowAdditional && <>
						<Spacer dir={SPACER_DIRECTION.VERTICAL} size={5} />
						<button onClick={() => changeFieldCount(true)} className={styles.addMoreBtn} aria-label={'Add Carrier Lookup FIeld'}>+</button>
					</>}
				</div>
			}
			{
				newFields > 0 && new Array(newFields).fill(null).map((_, key) => {
					return <div key={key} className={styles.inputContainer} style={{ marginTop: '5px' }}>
						<div style={{ flex: 1 }}>
							<TypeAhead
								name={`Filter-Option-Carrier-Lookup-${key}`}
								value={getLabel(inputVals[key + 1], selectOpts[key + 1]) || ''}
								onChange={(v) => updateSelectCarrier(v, key + 1, false)}
								onSelected={(v) => updateSelectCarrier(v, key + 1, true)}
								options={selectOpts[key + 1] || []}
								keepErrorSpace={false}
								Icon={SearchIcon}
							/>
						</div>
						<>
							<Spacer dir={SPACER_DIRECTION.VERTICAL} size={5} />
							<button onClick={() => changeFieldCount(false, key + 1)} className={styles.addMoreBtn} aria-label={'Remove Carrier Lookup Field'}>-</button>
						</>
					</div>
				})
			}
		</>
	)
}
