import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import { ListAddIcon } from '@img/icons';
import { navigator } from '@types';

const fulfilmentNav = () => {
	const root = `/fulfilment`;
	return [
		{
			label: 'Unallocated',
			icon: (color:string) => <ManageSearchIcon style={{ color: color }} />,
			link: `${root}/unallocated`,
			enabled: true,
			activeURL: 'unallocated'
		},
		{
			label: 'Superdock',
			icon: (color:string) => <ListAddIcon color={color} />,
			link: `${root}/superdock`,
			enabled: true,
			activeURL: 'superdock'
		},
		{
			label: 'Carrier Recommendation',
			icon: (color:string) => <WorkspacePremiumOutlinedIcon style={{ color: color }} />,
			link: `${root}/carrier_recommendation`,
			enabled: true,
			activeURL: 'carrier_recommendation'
		}
	] as navigator[];
}

export default fulfilmentNav;
