import {
	useState,
	useEffect,
	useCallback,
	useRef,
	ChangeEvent
} from 'react';
import styles from '../FilterOptions.module.scss';
import { Spacer, TypeAhead } from '@components/index';
import { SPACER_DIRECTION, TypeAheadOption } from '@types';
import { debounce } from 'lodash';
import { useGetShipperCarrier } from '@api';
import SearchIcon from '@mui/icons-material/Search';

type FilterProps = {
    meta: any;
    header: any;
    filterData: any;
    onChange: (val:any) => void;
	filterType: 'shipperLookup' | 'shipperLookupExternalId';
}

const getLabel = (value: string, options: TypeAheadOption[]) => {
	const option = options?.find((opt) => opt.value === value);

	return (option && typeof option.label === 'string') ? option.label : value;
}

export const FilterShipperLookup = (props:FilterProps) => {
	const {
		meta,
		filterData,
		onChange,
		filterType,
	} = props;
	const [newFields, setNewFields] = useState(0);
	const [inputVals, setInputVals] = useState<any[]>([]);
	const [selectOpts, setSelectOpts] = useState<any[]>([]);
	const currentSearchField = useRef<number>(0);
	const {
		loadingShippersCarriers,
		shippersCarriers,
		getShipperCarrierError,
		callGetShippersCarriers
	} = useGetShipperCarrier();
	const operator = filterType === 'shipperLookupExternalId' ? 'is' : 'contains';
	const changeFieldCount = (add:boolean, key:number = 0) => {
		const newFilterData = { ...filterData };
		if (add) {
			setNewFields(newFields + 1);
			newFilterData.repeats = newFields + 1;
			newFilterData.value = inputVals;
		} else {
			if (key > 0) {
				const cloned = [...inputVals].filter((_, index) => {
					return index !== key;
				});
				setInputVals(cloned);
				newFilterData.value = cloned;
			}
			setNewFields(newFields - 1);
			newFilterData.repeats = newFields - 1;
		}
		newFilterData.columnField = meta?.databaseLocation;
		newFilterData.operatorValue = newFields === 0 ? operator : 'In';
		onChange(newFilterData);
	}

	const fetchShippers = useCallback(debounce((v) => {
		// if isOnboard == true get all shippers including staging user else only active shippers
		const searchApi = meta?.filterOptions?.isOnboard ? 'search-include-staging' : 'search';
		callGetShippersCarriers('shipper', encodeURIComponent(v), searchApi);
	}, 500), []);

	const updateSelectShipper = (v: ChangeEvent<HTMLInputElement> | string | TypeAheadOption, i:number, fromSearch:boolean) => {
		const cloned = [...inputVals];
		currentSearchField.current = i;
		if ((v as ChangeEvent<HTMLInputElement>).target && (v as ChangeEvent<HTMLInputElement>).target.value !== '' && !fromSearch) {
			fetchShippers((v as ChangeEvent<HTMLInputElement>).target.value);
			cloned[i] = (v as ChangeEvent<HTMLInputElement>).target.value;
			setInputVals(cloned);
		} else if ((v as ChangeEvent<HTMLInputElement>).target && (v as ChangeEvent<HTMLInputElement>).target.value === '') {
			cloned[i] = '';
			setInputVals(cloned);
		} else if (fromSearch) {
			cloned[i] = (v as TypeAheadOption).value;
			setInputVals(cloned);
			const newFilterData = {
				...filterData,
				value: cloned,
				repeats: newFields,
				columnField: meta?.databaseLocation,
				operatorValue: newFields === 0 ? operator : 'In'
			}
			onChange(newFilterData);
		}
	}

	useEffect(() => {
		if (shippersCarriers) {
			const shippers = shippersCarriers.searchResult.shipper;
			const newOpts = shippers?.map((item) => {
				const customerName = item.bus_name ? item.bus_name : item.trade_name;

				return {
					value: filterType === 'shipperLookupExternalId' ? item.customer_external_id : customerName,
					label: customerName
				}
			});
			const clonedSelectOpts = [...selectOpts];
			clonedSelectOpts[currentSearchField.current ?? 0] = newOpts;
			setSelectOpts(clonedSelectOpts);
		}
		if (getShipperCarrierError) console.log(getShipperCarrierError);
	}, [shippersCarriers, getShipperCarrierError, filterType])

	useEffect(() => {
		if (filterData?.value) {
			setInputVals(filterData.value || []);
			setNewFields(filterData.repeats || 0);
		} else {
			setInputVals([]);
			setNewFields(0);
		}
	}, [filterData]);
	return (
		<>
			{
				<div className={styles.inputContainer}>
					<div style={{ flex: 1 }}>
						<TypeAhead
							name={'Filter-Option-Shipper-Lookup'}
							value={getLabel(inputVals[0], selectOpts[0]) || ''}
							onChange={(v) => updateSelectShipper(v, 0, false)}
							onSelected={(v) => updateSelectShipper(v as string, 0, true)}
							options={selectOpts[0] || []}
							keepErrorSpace={false}
							Icon={SearchIcon}
							isLoadingOptions={loadingShippersCarriers}
						/>
					</div>
					{meta?.filterOptions?.allowAdditional && <>
						<Spacer dir={SPACER_DIRECTION.VERTICAL} size={5} />
						<button onClick={() => changeFieldCount(true)} className={styles.addMoreBtn} aria-label={'Add Shipper Lookup Field'}>+</button>
					</>}
				</div>
			}
			{
				newFields > 0 && new Array(newFields).fill(null).map((_, key) => {
					return <div key={key} className={styles.inputContainer} style={{ marginTop: '5px' }}>
						<div style={{ flex: 1 }}>
							<TypeAhead
								name={`Filter-Option-Shipper-Lookup-${key}`}
								value={getLabel(inputVals[key + 1], selectOpts[key + 1]) || ''}
								onChange={(v) => updateSelectShipper(v, key + 1, false)}
								onSelected={(v) => updateSelectShipper(v as string, key + 1, true)}
								options={selectOpts[key + 1] || []}
								keepErrorSpace={false}
								Icon={SearchIcon}
							/>
						</div>
						<>
							<Spacer dir={SPACER_DIRECTION.VERTICAL} size={5} />
							<button onClick={() => changeFieldCount(false, key + 1)} className={styles.addMoreBtn} aria-label={'Remove Shipper Lookup Field'}>-</button>
						</>
					</div>
				})
			}
		</>
	)
}
