import { useEffect, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import styles from './Modal.module.scss';
import CloseIcon from '@mui/icons-material/Close';

type ModalProps = {
	open: boolean;
	toggleOpen: (b:boolean) => void;
	showCloseButton?: boolean;
	closeOnEscape?: boolean;
	closeOnClickOutside?: boolean;
}

export const Modal = (props:PropsWithChildren<ModalProps>) => {
	const {
		open,
		toggleOpen,
		showCloseButton = true,
		closeOnEscape,
		closeOnClickOutside,
		children
	} = props;
	useEffect(() => {
		if (!closeOnEscape) return;
		if (!open) return;
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				toggleOpen(false);
			}
		};
		document.addEventListener('keydown', handleKeyDown);
		return () => document.removeEventListener('keydown', handleKeyDown);
	}, [open]);
	return (
		<>
			{open && createPortal(<div data-testid={'@Ofload-Modal'} className={styles.container} onClick={() => { closeOnClickOutside && toggleOpen(false) }}>
				<div className={styles.modal} onClick={(e) => { e.stopPropagation() }}>
					{showCloseButton && <button onClick={() => { toggleOpen(false) }} className={styles.closeButton} aria-label={'Toggle Modal Button'}><CloseIcon /></button>}
					{children}
				</div>
			</div>, document.body)}
		</>
	);
}
