import { useState, useEffect } from 'react';
import styles from './TempChip.module.scss';
import { CHIP_SIZE, TEMP_NAMES } from '@types';
import WbSunnyIcon from '@mui/icons-material/WbSunnyOutlined';
import AirSharpIcon from '@mui/icons-material/AirSharp';
import AcUnitIcon from '@mui/icons-material/AcUnit';

type TempChipProps = {
	name: TEMP_NAMES | number;
	iconOnly?: boolean;
	selectable?: boolean;
	onClick?: (t:TEMP_NAMES|number) => void;
	isSelected?: boolean;
	size?: CHIP_SIZE;
}

export const TempChip = (props: TempChipProps) => {
	const { name, iconOnly = false, selectable = false, onClick, isSelected = false, size= CHIP_SIZE.LARGE } = props;
	const [displayName, setDisplayName] = useState<TEMP_NAMES | string>('');
	const icon = (temp: TEMP_NAMES | string) => {
		switch (temp) {
		case TEMP_NAMES.AMBIENT:
			return (
				<WbSunnyIcon
					data-testid={'@Ofload-TempChip-AmbientIcon'}
					className={`
						${styles['chip-icon']}
						${selectable && !isSelected ? styles.inactive : styles.activeAmbient}
					`}
				/>
			)
		case TEMP_NAMES.CHILLED:
			return (
				<AirSharpIcon
					data-testid={'@Ofload-TempChip-ChilledIcon'}
					className={`
						${styles['chip-icon']}
						${selectable && !isSelected ? styles.inactive : styles.activeChilled}
					`}
				/>
			)
		case TEMP_NAMES.FROZEN:
			return (
				<AcUnitIcon
					data-testid={'@Ofload-TempChip-FrozenIcon'}
					className={`
						${styles['chip-icon']}
						${selectable && !isSelected ? styles.inactive : styles.activeFrozen}
					`}
				/>
			)
		default:
			return '';
		}
	}
	useEffect(() => {
		if (typeof name === 'number') {
			switch (name) {
			case 1:
				setDisplayName(TEMP_NAMES.AMBIENT);
				break;
			case 2:
				setDisplayName(TEMP_NAMES.CHILLED);
				break;
			case 3:
				setDisplayName(TEMP_NAMES.FROZEN);
				break;
			default:
				break;
			}
		} else {
			let realName:TEMP_NAMES | string = name;
			if (typeof name === 'string') {
				realName = name.toLowerCase();
				realName = realName.charAt(0).toUpperCase() + realName.slice(1);
			}
			setDisplayName(realName);
		}
	}, [name]);

	const getSizeClass = () => {
		switch (size) {
			case CHIP_SIZE.SMALL:
				return styles.small;
			case CHIP_SIZE.MEDIUM:
				return styles.medium;
			case CHIP_SIZE.LARGE:
				return styles.large;
			default:
				return;
		}
	}
	return (
		<div>
			<span
				data-testid={'@Ofload-TempChip'}
				className={`
                    ${styles.chip}
                    ${selectable && !isSelected ? styles.unselected : styles[displayName]}
                    ${iconOnly && styles.iconOnly}
                    ${selectable && styles.pointer}
					${getSizeClass()}
				`
				}
				onClick={() => selectable ? onClick?.(name) : null}
			>
				{icon(displayName)}
				{!iconOnly && <span>{displayName}</span>}
			</span>
		</div>
	);
}
