import { getTruckTypeLabel, getTruckSizeLabel } from './truckSizeTypesLabels';
import { TRUCK_SIZE, TRUCK_TYPE, IContainerOpts } from '@types';

const containerTruckTypeOptions = [
	{
		label: getTruckTypeLabel(TRUCK_TYPE.ANY),
		value: TRUCK_TYPE.ANY,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.FLAT_TOP),
		value: TRUCK_TYPE.FLAT_TOP,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.SIDELOADER),
		value: TRUCK_TYPE.SIDELOADER,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.SKELETON),
		value: TRUCK_TYPE.SKELETON,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.OPEN_TOP),
		value: TRUCK_TYPE.OPEN_TOP,
	}
]

const containersOptions: IContainerOpts[] = [
	{
		label: getTruckSizeLabel(TRUCK_SIZE.CONTAINER_20FT_COASTAL),
		value: TRUCK_SIZE.CONTAINER_20FT_COASTAL,
		options: containerTruckTypeOptions
	},
	{
		label: getTruckSizeLabel(TRUCK_SIZE.CONTAINER_40FT_COASTAL),
		value: TRUCK_SIZE.CONTAINER_40FT_COASTAL,
		options: containerTruckTypeOptions
	},
	{
		label: getTruckSizeLabel(TRUCK_SIZE.CONTAINER_46FT_COASTAL),
		value: TRUCK_SIZE.CONTAINER_46FT_COASTAL,
		options: containerTruckTypeOptions
	},
	{
		label: getTruckSizeLabel(TRUCK_SIZE.CONTAINER_48FT_COASTAL),
		value: TRUCK_SIZE.CONTAINER_48FT_COASTAL,
		options: containerTruckTypeOptions
	},
];

const looseLoadTruckTypeOptions = [
	{
		label: getTruckTypeLabel(TRUCK_TYPE.TIPPER),
		value: TRUCK_TYPE.TIPPER,
	}
]

export const getTruckOptions = (cargoType: any, capacityType: any) => {
	if (cargoType === 'OTHER' && capacityType === 'FTL') {
		return containersOptions;
	}
	if (cargoType === 'LOOSE' && capacityType === 'FTL') {
		return [
			{
				label: getTruckSizeLabel(TRUCK_SIZE.SINGLE),
				value: TRUCK_SIZE.SINGLE,
				options: looseLoadTruckTypeOptions
			},
			{
				label: getTruckSizeLabel(TRUCK_SIZE.B_DOUBLE),
				value: TRUCK_SIZE.B_DOUBLE,
				options: looseLoadTruckTypeOptions
			},
			{
				label: getTruckSizeLabel(TRUCK_SIZE.B_TRIPLE),
				value: TRUCK_SIZE.B_TRIPLE,
				options: looseLoadTruckTypeOptions
			},
			{
				label: getTruckSizeLabel(TRUCK_SIZE.A_DOUBLE),
				value: TRUCK_SIZE.A_DOUBLE,
				options: looseLoadTruckTypeOptions
			}
		];

	}
	if (cargoType === 'PALLETS' && capacityType === 'FTL') {
		return [
			{
				label: getTruckSizeLabel(TRUCK_SIZE.RIGID),
				value: TRUCK_SIZE.RIGID,
				options: [
					{
						label: getTruckTypeLabel(TRUCK_TYPE.ANY),
						value: TRUCK_TYPE.ANY,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER),
						value: TRUCK_TYPE.TAUTLINER,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.PANTECH),
						value: TRUCK_TYPE.PANTECH,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.FLAT_TOP),
						value: TRUCK_TYPE.FLAT_TOP,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.OPEN_TOP),
						value: TRUCK_TYPE.OPEN_TOP,
					},
				]
			},
			{
				label: getTruckSizeLabel(TRUCK_SIZE.SINGLE),
				value: TRUCK_SIZE.SINGLE,
				options: [
					{
						label: getTruckTypeLabel(TRUCK_TYPE.ANY),
						value: TRUCK_TYPE.ANY,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER),
						value: TRUCK_TYPE.TAUTLINER,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.PANTECH),
						value: TRUCK_TYPE.PANTECH,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.FLAT_TOP),
						value: TRUCK_TYPE.FLAT_TOP,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_STRAIGHT_DECK),
						value: TRUCK_TYPE.TAUTLINER_STRAIGHT_DECK,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_DROP_DECK),
						value: TRUCK_TYPE.TAUTLINER_DROP_DECK,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_MEZZANINE_DECK),
						value: TRUCK_TYPE.TAUTLINER_MEZZANINE_DECK,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.UTE),
						value: TRUCK_TYPE.UTE,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.OPEN_TOP),
						value: TRUCK_TYPE.OPEN_TOP,
					}
				]
			},
			{
				label: getTruckSizeLabel(TRUCK_SIZE.B_DOUBLE),
				value: TRUCK_SIZE.B_DOUBLE,
				options: [
					{
						label: getTruckTypeLabel(TRUCK_TYPE.ANY),
						value: TRUCK_TYPE.ANY,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER),
						value: TRUCK_TYPE.TAUTLINER,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.PANTECH),
						value: TRUCK_TYPE.PANTECH,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.FLAT_TOP),
						value: TRUCK_TYPE.FLAT_TOP,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_STRAIGHT_DECK),
						value: TRUCK_TYPE.TAUTLINER_STRAIGHT_DECK,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_DROP_DECK),
						value: TRUCK_TYPE.TAUTLINER_DROP_DECK,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_MEZZANINE_DECK),
						value: TRUCK_TYPE.TAUTLINER_MEZZANINE_DECK,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.OPEN_TOP),
						value: TRUCK_TYPE.OPEN_TOP,
					}
				]
			},
			{
				label: getTruckSizeLabel(TRUCK_SIZE.B_DOUBLE_HIGH_CUBE),
				value: TRUCK_SIZE.B_DOUBLE_HIGH_CUBE,
				options: [
					{
						label: getTruckTypeLabel(TRUCK_TYPE.ANY),
						value: TRUCK_TYPE.ANY,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER),
						value: TRUCK_TYPE.TAUTLINER,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_STRAIGHT_DECK),
						value: TRUCK_TYPE.TAUTLINER_STRAIGHT_DECK,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_DROP_DECK),
						value: TRUCK_TYPE.TAUTLINER_DROP_DECK,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_MEZZANINE_DECK),
						value: TRUCK_TYPE.TAUTLINER_MEZZANINE_DECK,
					}
				]
			},
			{
				label: getTruckSizeLabel(TRUCK_SIZE.SUPER_B_DOUBLE),
				value: TRUCK_SIZE.SUPER_B_DOUBLE,
				options: [
					{
						label: getTruckTypeLabel(TRUCK_TYPE.ANY),
						value: TRUCK_TYPE.ANY,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER),
						value: TRUCK_TYPE.TAUTLINER,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_STRAIGHT_DECK),
						value: TRUCK_TYPE.TAUTLINER_STRAIGHT_DECK,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_DROP_DECK),
						value: TRUCK_TYPE.TAUTLINER_DROP_DECK,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_MEZZANINE_DECK),
						value: TRUCK_TYPE.TAUTLINER_MEZZANINE_DECK,
					}
				]
			},
			{
				label: getTruckSizeLabel(TRUCK_SIZE.B_TRIPLE),
				value: TRUCK_SIZE.B_TRIPLE,
				options: [
					{
						label: getTruckTypeLabel(TRUCK_TYPE.ANY),
						value: TRUCK_TYPE.ANY,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER),
						value: TRUCK_TYPE.TAUTLINER,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_STRAIGHT_DECK),
						value: TRUCK_TYPE.TAUTLINER_STRAIGHT_DECK,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_DROP_DECK),
						value: TRUCK_TYPE.TAUTLINER_DROP_DECK,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_MEZZANINE_DECK),
						value: TRUCK_TYPE.TAUTLINER_MEZZANINE_DECK,
					}
				]
			},
			{
				label: getTruckSizeLabel(TRUCK_SIZE.A_DOUBLE),
				value: TRUCK_SIZE.A_DOUBLE,
				options: [
					{
						label: getTruckTypeLabel(TRUCK_TYPE.ANY),
						value: TRUCK_TYPE.ANY,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER),
						value: TRUCK_TYPE.TAUTLINER,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.PANTECH),
						value: TRUCK_TYPE.PANTECH,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.FLAT_TOP),
						value: TRUCK_TYPE.FLAT_TOP,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_STRAIGHT_DECK),
						value: TRUCK_TYPE.TAUTLINER_STRAIGHT_DECK,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_DROP_DECK),
						value: TRUCK_TYPE.TAUTLINER_DROP_DECK,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_MEZZANINE_DECK),
						value: TRUCK_TYPE.TAUTLINER_MEZZANINE_DECK,
					},
					{
						label: getTruckTypeLabel(TRUCK_TYPE.OPEN_TOP),
						value: TRUCK_TYPE.OPEN_TOP,
					}
				]
			},
		];
	}

	if (
		(cargoType === 'PALLETS' && capacityType === 'PARTIAL') ||
        capacityType === 'VOLUMETRIC'
	) {
		return [
			{
				label: getTruckTypeLabel(TRUCK_TYPE.ANY),
				value: TRUCK_TYPE.ANY,
			},
			{
				label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER),
				value: TRUCK_TYPE.TAUTLINER,
			},
			{
				label: getTruckTypeLabel(TRUCK_TYPE.PANTECH),
				value: TRUCK_TYPE.PANTECH,
			},
			{
				label: getTruckTypeLabel(TRUCK_TYPE.FLAT_TOP),
				value: TRUCK_TYPE.FLAT_TOP,
			},
			{
				label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_STRAIGHT_DECK),
				value: TRUCK_TYPE.TAUTLINER_STRAIGHT_DECK,
			},
			{
				label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_DROP_DECK),
				value: TRUCK_TYPE.TAUTLINER_DROP_DECK,
			},
			{
				label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_MEZZANINE_DECK),
				value: TRUCK_TYPE.TAUTLINER_MEZZANINE_DECK,
			},
			{
				label: getTruckTypeLabel(TRUCK_TYPE.SIDELOADER),
				value: TRUCK_TYPE.SIDELOADER,
			},
			{
				label: getTruckTypeLabel(TRUCK_TYPE.SKELETON),
				value: TRUCK_TYPE.SKELETON,
			},
			{
				label: getTruckTypeLabel(TRUCK_TYPE.UTE),
				value: TRUCK_TYPE.UTE,
			},
			{
				label: getTruckTypeLabel(TRUCK_TYPE.OPEN_TOP),
				value: TRUCK_TYPE.OPEN_TOP,
			}
		]
	}
	return []
}